import { Base, StatusStaticReference } from './generic';

export enum ChallengeModeStaticReference {
  TEAM_DUEL = 'CM_5',
  TEAM_VS_TEAM = 'CM_4',
  TOGETHER_AS_TEAM = 'CM_3',
  TEAM_COOPERATION = 'CM_6',
  PLAYER_VS_PLAYER = 'CM_1',
  SINGLE_PLAYER = 'CM_7',
}

export enum ChallengeTypeStaticReference {
  CLASSIC = 'CT_3',
  FASTEST = 'CT_4',
  REACHED_AS_TEAM = 'CT_5',
  ACHIEVER = 'CT_6',
}

export enum ChallengeGoalStaticReference {
  TARGET = 'CG_6',
  MOST_POINTS_WIN = 'CG_4',
  LEAST_POINTS_WIN = 'CG_5',
}

export enum ChallengeAudienceStaticReference {
  INDIVIDUAL = 'A_1',
  TEAM = 'A_2',
}

export enum ChallengeType {
  COMPETITIVE = 'COMPETITIVE',
  COOPERATIVE = 'COOPERATIVE',
  SELF_IMPROVEMENT = 'SELF_IMPROVEMENT',
}

export interface ChallengeLeaderboardActor extends Base {
  position?: number;
  username: string;
  urlAvatar: string;
  units?: number;
}

export interface ChallengeCombinations {
  isIndividualCompetition: boolean;
  isIndividualRace: boolean;
  isIndividualCooperation: boolean;
  isIndividualAchiever: boolean;
  isTeamCompetition: boolean;
  isTeamRace: boolean;
  isTeamCooperation: boolean;
  isCompetitive: boolean;
  isCooperative: boolean;
  getChallengeType: () => ChallengeType;
}

export interface NewChallenge {
  challengeInfo: {
    illustration: {
      name: string;
      description: string;
      url: string;
      organizationId: number;
      imageType: string;
      reference: string;
      default: boolean;
    };
    reference: string;
    name: string;
    endDate: number;
    metric?: string;
    category?: string;
    description: string;
    unitMeasureName: string;
    reachedPercentage?: boolean; // check if is necessary
    goal?: ChallengeGoalStaticReference;
    mode: {
      url: string;
      staticReference: ChallengeModeStaticReference;
    };
    type: {
      url: string;
      staticReference: ChallengeTypeStaticReference;
    };
    units: {
      goal?: number;
      current?: number;
    };
    startTime: number;
    status: {
      name: string;
      staticReference: StatusStaticReference;
    };
    audienceStaticReference: ChallengeAudienceStaticReference;
    coinsPerUnit?: number;
    potentialCoins: number;
    totalPoints: number;
    goalStatement: string;
  };
  challengeLeaderboard: {
    actors: ChallengeLeaderboardActor[];
  };
}

export interface NewChallengeSlide {
  rotationDuration: number;
  challenges: NewChallenge[];
}

export const challengesOrderByStatus: StatusStaticReference[] = [
  StatusStaticReference.ON_GOING,
  StatusStaticReference.CALCULATING_RESULTS,
  StatusStaticReference.DONE,
  StatusStaticReference.COMPLETED,
];
