import { Locale } from '@/shared/types/generic';
import LayoutType from '@/shared/types/layouts';
import {
  OrganizationDisplay,
  OrganizationDisplaysResponse,
} from '@/shared/types/organization';

const defaultOrganizationDisplayConfig = () => ({
  language: 'en-US',
  activePin: true,
  layoutType: LayoutType.ALL_CHALLENGES,
  orgName: 'Engineering Org',
  challenges: {
    enabled: true,
  },
  metrics: {
    enabled: true,
  },
});

export const mockSingleOrganizationDisplay = (): OrganizationDisplay => ({
  displayCode: '19409550',
  name: 'Test Log',
  description: 'Description 1',
  ...defaultOrganizationDisplayConfig(),
  hasConfettis: false,
  hasSound: false,
});

export const mockOrganizatioDisplays: OrganizationDisplay[] = [
  {
    displayCode: '1',
    name: 'Display 1',
    description: 'Description 1',
    ...defaultOrganizationDisplayConfig(),
    hasConfettis: false,
    hasSound: false,
  },
  {
    displayCode: '2',
    name: 'Display 2',
    description: 'Description 2',
    ...defaultOrganizationDisplayConfig(),
    hasConfettis: false,
    hasSound: false,
  },
  {
    displayCode: '3',
    name: 'Display 3',
    description: 'Description 3',
    ...defaultOrganizationDisplayConfig(),
    hasConfettis: false,
    hasSound: false,
  },
  {
    displayCode: '4',
    name: 'Display 4',
    description: 'Description 4',
    ...defaultOrganizationDisplayConfig(),
    hasConfettis: false,
    hasSound: false,
  },
  {
    displayCode: '5',
    name: 'Display 5',
    description: 'Description 5',
    ...defaultOrganizationDisplayConfig(),
    hasConfettis: false,
    hasSound: false,
  },
];

export const mockOrganizatioDisplaysResponse: OrganizationDisplaysResponse = {
  language: Locale.PT,
  displays: mockOrganizatioDisplays,
};
